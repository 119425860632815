@keyframes fadeInUp {
  from {
      transform: translate3d(0,-14px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,-14px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@keyframes fadeInUpModal {
  from {
    transform: translate3d(-50%,calc(-50% + 21px),0)
  }

  to {
      transform: translate3d(-50%,-50%,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUpModal {
  from {
      transform: translate3d(-50%,calc(-50% - 14px),0)
  }

  to {
      transform: translate3d(-50%,-50%,0);
      opacity: 1
  }
}

.animated {
  animation-duration: .25s;
  animation-fill-mode: both;
  -webkit-animation-duration: .25s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.fadeInUpModal {
  opacity: 0;
  animation-name: fadeInUpModal;
  -webkit-animation-name: fadeInUpModal;
}

.App {
  background: #181c28;
  min-height: 100vh;
  position: relative;
  padding: 0 0 50px;
}

.App-locked {
  overflow: hidden;
}

.App-backdrop {
  background: rgba(216,226,237,0.3);
  height: 100vh;
  left: 0;
  opacity: .5;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 20;
}

.inelligible button {
  cursor: not-allowed;
  opacity: .6;
}

.inelligible button:hover {
  cursor: not-allowed;
}

.inelligibe-tag {
  color: white;
  cursor: pointer;
}

@media only screen and (min-width: 601px) {
.modal-footer {
    align-items: flex-end;
    background-color: #283141;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    padding: 0 12px 12px;
    width: calc(100% - 24px);
    position: absolute;
  }
}

.App-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.player--title {
  padding: 0 6px;
}

.posit--title {
  width: 24px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 12px 0;
  position: relative;
}

ul li {
  padding: 0 12px;
}

li button {
  text-transform: capitalize;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.countdown-section span {
  border-radius: 16px;
  background-color: #1c212d;
  box-shadow: 4px 4px 16px rgb(0 0 0 / 7%);
  color: white;
  margin: 0 12px;
  padding: 6px 12px;
  text-align: center;
  width: 220px;
}

.countdown-section {
  align-items: center;
  background: #00ceb8;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  color: #022047;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 34px;
  font-weight: bold;
  min-height: 52px;
  position: sticky;
  top: 0;
  margin: 0 0 24px 0;
  padding: 12px;
  z-index: 19;
}

.keeper h1 {
  font-size: 32px;
}

.keeper h2 {
  font-size: 24px;
  text-transform: capitalize;
}

.keeper .playername {
  color: #00ceb8;
}

.keeper .cancel {
  background: white;
  background-image: none;
  border-radius: 4px;
  border: 0;
  color: black;
  cursor: pointer;
  font-size: 18px;
  padding: 6px;
}

.keeper .cancel:hover {
  box-shadow: 4px 4px 16px rgb(0 0 0 / 7%);
  opacity: .7;
}

.keeper .lockin {
  background: #00ceb8;
  background-image: none;
  border-radius: 4px;
  border: 0;
  color: #022047;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 0 8px;
  padding: 6px;
}

.keeper .lockin:hover {
  box-shadow: 4px 4px 16px rgb(0 0 0 / 7%);
  opacity: .7;
}

.googler {
  color: #fff;
  display: flex;
  padding: 12px 0;
}

.rounder {
  color: #fff;
  display: inline-block;
  width: 54px;
}

.headings {
  color: #fff;
  font-weight: bold;
  padding-bottom: 12px;
}

li:not(.headings) span.rounder {
  color: #b9c4d4;
}

li button {
  background: none;
  background-image: none;
  border: none;
  color: #fff;
  font-size: 18px;
  padding: 6px;
  max-width: calc(100% - 66px);
  text-align: left;
}

li button:hover {
  cursor: pointer;
  opacity: .7;
}

.fa-tag {
  color: #0370e3;
  font-size: 12px;
}

.ttip {
  border-radius: 6px;
  background-color: #181c28;
  font-size: 14px;
  left: 66px;
  margin: 34px 12px 0 0;
  opacity: .8;
  padding: 12px;
  position: absolute;
  font-weight: 600;
  color: white;
}

.ttip-name {
  text-transform: capitalize;
}

.fa-tag:hover {
  cursor: pointer;
}

.highlight {
  background: rgba(0,206,184,0.1);
  color: #00ceb8;
}

.team {
  background: rgba(58,70,91,0.5);
  border: 1px solid #3a465b;
  border-radius: 16px;
  display: flex;
  box-shadow: 4px 4px 16px rgb(0 0 0 / 7%);
  flex-direction: column;
  margin: 20px;
  width: 400px;
}

.team h1 {
  color: white;
  font-size: 24px;
  padding: 12px 0 8px 12px;
}

.modal {
  background: #283141;
  border: 1px solid #3a465b;
  color: #fff;
  border-radius: 16px;
  box-shadow: 4px 4px 16px rgb(0 0 0 / 7%);
  width: 500px;
  height: 300px;
  left: 50%;
  padding: 12px 18px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 21;
}

.designation {
  padding: 0 4px;
  font-size: 12px;
}

.Pos {
  width: 24px;
}

.PosQB {
  color: #ff2a6d;
}
 
.PosRB {
  color: #00ceb8
}

.PosWR {
  color: #58a7ff;
}

.PosTE {
  color: #ffae58;
}

.PosK {
  color: #bd66ff;
}

.PosDEF {
  color: #7988a1;
}

.filler {
  height: 50px;
}

@media only screen and (max-width: 600px) {
  .App {
    padding: 0 8px 50px;
  }

  .modal {
    border-radius: 0;
    transform: none;
    left: 0;
    top: 0;
    height: 100%;
    padding: 24px;
    width: calc(100% - 48px);
  }

  .fadeInUpModal {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
  }

  ul li button {
    font-size: 15px;
  }
}

.ari {color: #97233f;}
.atl {color: #a6192e;}
.bal {color: #251c71;}
.buf {color: #00338d;}
.car {color: #007ab8;}
.chi {color: #0b162a;}
.cin {color: #000000;}
.cle {color: #311d00;}
.dal {color: #041e42;}
.den {color: #002244;}
.det {color: #0076b6;}
.gb {color: #203731;}
.hou {color: #03202f;}
.ind {color: #003a70;}
.jac {color: #006778;}
.kc {color: #e31837;}
.lac {color: #0e243f;}
.lar {color: #0b215e;}
.lv {color: #000000;}
.mia {color: #00818a;}
.min {color: #4f2683;}
.ne {color: #002244;}
.no {color: #101820;}
.nyg {color: #0b2265;}
.nyj {color: #046a38;}
.oak {color: #000000;}
.phi {color: #064850;}
.pit {color: #000000;}
.sf {color: #aa0000;}
.sea {color: #002244;}
.tb {color: #d50a0a;}
.ten {color: #002a5c;}
.was {color: #842835;}
